import { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import fetchData from "../../hook/fetchData";
import { decodeToken, getEnv } from "@urecruits/api";
import { Stack, Skeleton } from '@mui/material';
import { store } from "../../store";
import { setFilterTypeCandidateJobs } from "../../store/reducers/candidateJobsReducer";
import { DashboardEmptyDataComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_RECRUITMENT } = getEnv()

const AppliedJobsTable = ({styles}) => {
	const [appliedJobs, setappliedJobs] = useState([])
	const [loading, setLoading] = useState(true)
	const [userId, setUserId] = useState()

	useEffect(()=>{
	const getUserId = async () => await decodeToken().then(data => data["https://urecruits.com/userId"]);
	getUserId().then(id => setUserId(id))
	},[])
	useEffect(() => {
		if(userId){
			setLoading(true)
			fetchData(`${API_RECRUITMENT}/api/job?limit=5&offset=0&filterType=applied&currentUserId=${userId}`).then(res => {
					setappliedJobs(res.rows);
					setLoading(false);
			}).catch((e) => {
				console.log("error Getting applied Jobs", e)
				setLoading(false)
			})
		}
	}, [userId])

	return (
		<div className="dashboard-table" style={styles}>
			<div className="dashboard-table__header">
				<p className="dashboard-table__header__title">
					Applied Jobs
				</p>
				<Link to={"/candidate/jobs"} className="dashboard-table__header__link" onClick={() => store.dispatch(setFilterTypeCandidateJobs("applied"))}>View all jobs</Link>
			</div>
			{!appliedJobs.length && !loading && <DashboardEmptyDataComponent />}
			{!!appliedJobs.length && 
			
			<table className="dashboard-table__table">
				<thead className="dashboard-table__thead">
					<td className="dashboard-table__thead__td">
						Job Title
					</td>
					<td className="dashboard-table__thead__td">
						Employeer
					</td>
					<td className="dashboard-table__thead__td">
						Salary Range
					</td>
					<td className="dashboard-table__thead__td">
						Status
					</td>
				</thead>
				<tbody className="dashboard-table__tbody">
					{loading && <Stack height={"100%"}>
						<Skeleton animation="wave" />
					</Stack>}
					{!loading && appliedJobs.length > 0 &&
						appliedJobs.map(job => {
							if (job) {
								return <tr className="dashboard-table__tbody__tr" key={job.id}>
									<td className="dashboard-table__tbody__td">
										<Link to="/" className="dashboard-table__link">
											{job.title || ""}
										</Link>
									</td>
									<td className="dashboard-table__tbody__td green">
										{job.employer || ""}
									</td>
									{<td className="dashboard-table__tbody__td gray">
										{`${job.salaryYearMin || ''}$ - ${job.salaryYearMax || ''}$ PA`}
									</td>}
									<td className="dashboard-table__tbody__td">
										{ job.status ?
										<div className={`dashboard-table__status ${job.status === "publish" ? "publish" : job.status === "closed" ? "not-publish" : ""}`}>
											{job?.status === "publish" ? "Active" : job.status === "closed" ? "Non-Active" : ""}
										</div> : ''}
									</td>
								</tr>
							} else {
								return;
							}
						})
					}
				</tbody>
			</table>
			}
		</div>
	);
};

export default AppliedJobsTable;