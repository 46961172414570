import { Link, useNavigate } from "react-router-dom";
import AppliedJobsTable from "../components/Dashboard/AppliedJobsTable";
import TotalCount from "../components/Dashboard/TotalCount";

import tempUser from "../image/temp-user.png";
import noData from "../image/icon/no-data.svg";
import { useEffect, useState } from "react";
import CalendarDayView from "../components/CalendarDayView/CalendarDayView";
import { getEnv } from "@urecruits/api";
import fetchData from "../hook/fetchData";
import JobMatches from "../components/Dashboard/JobMatches";
import RescheduleTask from '../components/Dashboard/RescheduleTasks';

const { API_RECRUITMENT } = getEnv()

const myProfileViews = [
  {
    name: "01.11",
    uv: 4000,
  },
  {
    name: "02.11",
    uv: 3000,
  },
  {
    name: "03.11",
    uv: 2000,
  },
  {
    name: "04.11",
    uv: 2780,
  },
  {
    name: "05.11",
    uv: 1890,
  },
  {
    name: "06.11",
    uv: 2390,
  },
  {
    name: "07.11",
    uv: 3490,
  },
  {
    name: "08.11",
    uv: 4000,
  },
  {
    name: "09.11",
    uv: 3000,
  },
  {
    name: "10.11",
    uv: 2000,
  },
  {
    name: "11.11",
    uv: 2780,
  },
  {
    name: "12.11",
    uv: 1890,
  },
  {
    name: "13.11",
    uv: 2390,
  },
  {
    name: "14.11",
    uv: 3490,
  },
  {
    name: "15.11",
    uv: 4000,
  },
  {
    name: "16.11",
    uv: 3000,
  },
  {
    name: "17.11",
    uv: 2000,
  },
  {
    name: "18.11",
    uv: 2780,
  },
  {
    name: "19.11",
    uv: 1890,
  },
  {
    name: "20.11",
    uv: 2390,
  },
  {
    name: "21.11",
    uv: 3490,
  },
  {
    name: "22.11",
    uv: 4000,
  },
  {
    name: "23.11",
    uv: 3000,
  },
  {
    name: "24.11",
    uv: 2000,
  },
  {
    name: "25.11",
    uv: 2780,
  },
  {
    name: "26.11",
    uv: 1890,
  },
  {
    name: "27.11",
    uv: 2390,
  },
  {
    name: "28.11",
    uv: 3490,
  },
  {
    name: "29.11",
    uv: 3490,
  },
  {
    name: "30.11",
    uv: 3490,
  },
];

const CandidateDashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [savedjobs, setSavedJobs] = useState(0)
  const [matchedJobs, setMatchedJobs] = useState(0)

  useEffect(() => {
    fetchData(`${API_RECRUITMENT}/api/candidate`).then(
      (dd)=>{
        const fullName=`${dd?.user?.firstname} ${dd?.user?.lastname}`
        setData(fullName)
      }
    );
    fetchData(`${API_RECRUITMENT}/api/subscribe/jobs/saved/count`).then(data => setSavedJobs(data.todaySavedJobs || 0))
    fetchData(`${API_RECRUITMENT}/api/job/match/count`).then(data => setMatchedJobs(data.todayMatchedJobs || 0))
  }, []);
  useEffect(() => {
    const cookies = document.cookie.split("; ");
    if (cookies) {
      for (let i = 0; i < cookies.length; i++) {
        const [cookieName, cookieValue] = cookies[i].split("=");
        if (cookieName === "jobId") {
          navigate(`/job/${decodeURIComponent(cookieValue)}`);
        }
      }
    }
  }, [document.cookie]);

  return (
    <section className="dashboard">
      <h2 className="dashboard__headline">Hello, {data && data}</h2>
      <div className="dashboard__inner candidate">
        <div className="dashboard__top">
          <JobMatches />
          <div className="dashboard__top__middle">
            <TotalCount
              title={"matching jobs"}
              value={matchedJobs}
              label={"for today"}
              position={"left"}
            />
            <TotalCount
              title={"your saved jobs"}
              value={savedjobs}
              label={"for today"}
              position={"right"}
            />
            {/* <AreaChart title={"My profile views"} data={myProfileViews} /> */}
            <AppliedJobsTable styles={{ width: "100%"}} />
          </div>
          <CalendarDayView />
          {/* <CandidateCalendar/> */}
        </div>
        <div className="dashboard__bottom">
          <RescheduleTask />
          <TopCompanies />
        </div>
      </div>
    </section>
  );
};

export default CandidateDashboard;



const TopCompanies = () => {
  return (
    <div className="top-companies">
      <div className="top-companies__head">
        <p className="top-companies__head__headline">Top companies</p>
        <Link to="/" className="top-companies__head__link">
          View all
        </Link>
      </div>
      <ul className="top-companies__list">
        <li className="top-companies__item">
          <div className="top-companies__item__left">
            <p className="top-companies__item__name">Nickelodeon</p>
            <p className="top-companies__item__members">
              Total Member <span>12</span>
            </p>
          </div>
          <div className="top-companies__item__right">
            <ul className="top-companies__users">
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__count">
                <span>12</span>
              </li>
            </ul>
          </div>
        </li>
        <li className="top-companies__item">
          <div className="top-companies__item__left">
            <p className="top-companies__item__name">Nickelodeon</p>
            <p className="top-companies__item__members">
              Total Member <span>12</span>
            </p>
          </div>
          <div className="top-companies__item__right">
            <ul className="top-companies__users">
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__count">
                <span>12</span>
              </li>
            </ul>
          </div>
        </li>
        <li className="top-companies__item">
          <div className="top-companies__item__left">
            <p className="top-companies__item__name">Nickelodeon</p>
            <p className="top-companies__item__members">
              Total Member <span>12</span>
            </p>
          </div>
          <div className="top-companies__item__right">
            <ul className="top-companies__users">
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__count">
                <span>12</span>
              </li>
            </ul>
          </div>
        </li>
        <li className="top-companies__item">
          <div className="top-companies__item__left">
            <p className="top-companies__item__name">Nickelodeon</p>
            <p className="top-companies__item__members">
              Total Member <span>12</span>
            </p>
          </div>
          <div className="top-companies__item__right">
            <ul className="top-companies__users">
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__count">
                <span>12</span>
              </li>
            </ul>
          </div>
        </li>
        <li className="top-companies__item">
          <div className="top-companies__item__left">
            <p className="top-companies__item__name">Nickelodeon</p>
            <p className="top-companies__item__members">
              Total Member <span>12</span>
            </p>
          </div>
          <div className="top-companies__item__right">
            <ul className="top-companies__users">
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__count">
                <span>12</span>
              </li>
            </ul>
          </div>
        </li>
        <li className="top-companies__item">
          <div className="top-companies__item__left">
            <p className="top-companies__item__name">Nickelodeon</p>
            <p className="top-companies__item__members">
              Total Member <span>12</span>
            </p>
          </div>
          <div className="top-companies__item__right">
            <ul className="top-companies__users">
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__item">
                <img
                  src={tempUser}
                  alt=""
                  className="top-companies__users__img"
                />
              </li>
              <li className="top-companies__users__count">
                <span>12</span>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
};


const CandidateCalendar = () => {
  return (
    <div className="candidate-calendar">
      <p className="candidate-calendar__headline">Today, 02 November</p>
      <div className="candidate-calendar__inner">
        <img src={noData} alt="" className="candidate-calendar__empty" />
      </div>
    </div>
  );
};
