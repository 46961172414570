import { Link } from "react-router-dom";
import avatarIc from "../../image/icon/avatar.svg";
import { useEffect, useState } from "react";
import fetchData from "../../hook/fetchData";
import { getEnv } from "@urecruits/api";
import { Skeleton, Stack } from "@mui/material";
import { DashboardEmptyDataComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const {API_RECRUITMENT} = getEnv()

const EmployeesList = () => {
    const [data,setData] = useState([])
	const [isLoading,setIsLoading] = useState(true)
	const [isEmptyData,setIsEmptyData] = useState(false)
    useEffect(()=>{
        async function getInfo(){
            try{
			await fetchData(`${API_RECRUITMENT}/api/company/members?limit=20&offset=0`).then(data=>{
                if(!data.rows?.length){
                    setIsEmptyData(true)
                }else{
                    setData(data.rows)
                }
            })
            setIsLoading(false)

            }catch(error){
                console.log(error)
                setIsEmptyData(true)
				setIsLoading(false)
            }
        }
        getInfo().then()
    },[])
    return (
      <div className="employee-admin">
        <div className="employee-admin__head">
          <p className="employee-admin__head__headline">Employee</p>
          <Link to="/recruitment/manage-team-members" className="employee-admin__head__link">
            View all
          </Link>
        </div>
        {isLoading && <Stack height={"100%"} padding={"12px"}>
                <Skeleton animation="wave" />
            </Stack>
        }
        {
            isEmptyData && <DashboardEmptyDataComponent />
        }
        {
            !isEmptyData && !isLoading &&
            <ul className="employee-admin__list">
                {data.length >0 && 
                    data.map(row=>{
                        const name = (row?.user?.firstname || "")+" "+(row?.user?.lastname || "")
                        return <li className="employee-admin__item" key={row.id}>
                        <img
                          src={row?.user?.avatar || avatarIc}
                          alt="user name"
                          className="employee-admin__item__avatar"
                        />
                        <p className="employee-admin__item__name">{name}</p>
                      </li>
                    })
                }
        </ul>
        }
      </div>
    );
  };

  export default EmployeesList