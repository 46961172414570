import { useEffect, useState } from "react"
import axios from "axios";
import { getEnv } from "@urecruits/api";
import { useNavigate, useParams } from "react-router-dom";
import { IColumns } from "@ucrecruits/globalstyle/types/table-types";
import { TableCardViewComp } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import defaultAvatar from "../image/temp-company.png";
import { ASSESSMENT_TYPE } from "../enums/CandidateAssessmentTypeEnums";
import ViewSVG from "../components/SVG/ViewSVG";
import DomainResultModal from "../components/CandidateScoreboard/Popups/DomainResultModal";


const { API_RECRUITMENT } = getEnv()
const token: string = localStorage.getItem('token')
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};
const getStatusData = (cellValue) => {
  const label = cellValue === 'Completed' ? "Passed" : cellValue === "Rejected" ? "Failed" : "Pending"
  const className = cellValue === 'Completed' ? 'green' : cellValue === 'Rejected' ? 'red' : 'yellow'
  return {
    label,
    className
  }
}

const getAssessmentTypeLabel = (type) => {
  return type === ASSESSMENT_TYPE.DOMAIN_ASSESSMENT
    ? "Domain Assessment"
    : type === ASSESSMENT_TYPE.LIVE_TASK ? "Live Task Assessment"
      : type === ASSESSMENT_TYPE.TAKE_HOME ? "Take Home Task" : ""
}

const CandidateJobScoreboardScreen = () => {

  const navigate = useNavigate()
  const [jobTitle, setJobTitle] = useState("")
  const [resultPopup,setResultPopup] = useState({
    visible:false,
    type:"",
    score:0,
    comments:"",
    domainId:0,
    assessmentType:"",
  })
  const { jobId } = useParams()

  const handleClosePopup = ()=>{
    setResultPopup(prev=>({...prev,visible:false,type:"",score:0}))
  }

  const handleSubmitPopup = ()=>{
    if(resultPopup.assessmentType === ASSESSMENT_TYPE.DOMAIN_ASSESSMENT){
      navigate(`/candidate/scoreboard/${jobId}/domain-assessment/view/${resultPopup.domainId}`)
    }
    setResultPopup(prev=>({...prev,visible:false,type:"",score:0,domainId:0,assessmentType:""}))
  }


  const columns: Array<IColumns> = [
    {
      headerName: "Result",
      field: "result",
      renderCell: (cellValue, rowData) => {
        const data = getStatusData(cellValue)
        return <p className={`table__status-block--${data.className} candidate-table__status`}>
          {data.label}
        </p>
      },
      visibility: true,
      className: 'table__column__extra-small'
    },
    {
      headerName: "Assessment Name",
      field: "assessmentName",
      renderCell: (cellValue, rowData) => {
        return rowData.workflow?.title
      },
      mobileRenderCell: (cellValue, rowData) => {
        return rowData.workflow?.title
      },
      visibility: true,
    },
    {
      headerName: "Assessment Type",
      field: "assessmentType",
      renderCell: (cellValue, rowData) => {
        return getAssessmentTypeLabel(cellValue)
      },
      mobileRenderCell: (cellValue, rowData) => {
        const data = getStatusData(rowData.result)
        return <div className="candidate-table__title">
          <p className={`table__status-block--${data.className} candidate-table__status`}>
            {data.label}
          </p>
          <p className="candidate-table__text green">
            {getAssessmentTypeLabel(cellValue)}
          </p>
        </div>
      },
      visibility: true,
      isMobileTitle: true
    },
    {
      headerName: 'Company',
      field: 'company',
      renderCell: (cellValue, rowData) => {

        // return cellValue.name || '';
        return (<div className="candidate-table__company">
          <img
            src={!!cellValue.avatar ? cellValue.avatar : defaultAvatar} alt="avatar"
            className="candidate-table__company__avatar" />
          <p className="candidate-table__company__text">
            {cellValue.name || ""}
          </p>
        </div>
        )
      },
      mobileRenderCell: (cellValue, rowData) => {
        return (<div className="candidate-table__company">
          <img
            src={!!cellValue.avatar ? cellValue.avatar : defaultAvatar} alt="avatar"
            className="candidate-table__company__avatar" />
          <p className="candidate-table__company__text">
            {cellValue.name || ""}
          </p>
        </div>
        )
      },
      visibility: true,
    },
    {
      headerName: "Score",
      field: "totalScores",
      renderCell: (cellValue, rowData) => {
        return `${cellValue ? cellValue + "%" : "-"}`
      },
      mobileRenderCell: (cellValue, rowData) => {
        return `${cellValue ? cellValue + "%" : "-"}`
      },
      visibility: true,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      setActions: [
        {
          getAction: (row) => {
            const type =["Completed",'Rejected'].includes(row.result) ? row.result : "";

            if (!type) return;
            return {
              svg: <ViewSVG />,
              title: 'See details',
              onClick: (row, event) => {
                setResultPopup(()=>({visible:true,type,score: row.totalScores,domainId:row.workflow?.domainId,comments:row.comments,assessmentType:row.assessmentType}))
              },
              className: 'active'
            }
          }
        }
      ],
      visibility: true
    }
  ]

  useEffect(() => {
    axios.get(`${API_RECRUITMENT}/api/job/${jobId}`, config).then(data =>setJobTitle(data.data.title))
  }, [])

  const createAPIString = () => {
    return `${API_RECRUITMENT}/api/round/scoreboard/${jobId}`
  }
  return  <>
  <section className="candidate-job-scoreboard">
    <div className="table-screen-top">
      <div className="table-screen-top__wrapper">
        <div>
          <p style={{ fontSize: "16px" }} className="routes">
            <span
              onClick={() => {
                navigate("/candidate/scoreboard")
              }}
              style={{ color: "green", cursor: "pointer" }}>
              {" "}
              Scoreboard{" "}
            </span>
            /
          </p>
        </div>
      </div>
    </div>
    <div className="candidate-job-scoreboard__head">
      <div className="candidate-job-scoreboard__head__left">
        <h2 className="candidate-job-scoreboard__head__headline">
          {jobTitle && `${jobTitle}`}
        </h2>
      </div>
    </div>
    <TableCardViewComp
      columns={columns}
      listAPI={createAPIString()}
      emptyTable={{
        title: 'No Assessments yet.',
        buttonText: "To Scoreboard",
        handler: () => {
          navigate("/candidate/scoreboard")
        }
      }}
    />
  </section>
  {
      resultPopup.visible && resultPopup.type && <DomainResultModal type={resultPopup.type} onSubmit={{buttonText:resultPopup.assessmentType === ASSESSMENT_TYPE.DOMAIN_ASSESSMENT ? "See Answers":"Got it!",handler:()=>handleSubmitPopup()}} onClose={handleClosePopup}>
      <div>
        <p className="popup__body__text">Result: {resultPopup.score}%</p>
        {resultPopup.comments && 
        <p className="popup__body__text">Interview Notes: {resultPopup.comments}</p>
        }
      </div>
      </DomainResultModal>
    }
  </>
}
export default CandidateJobScoreboardScreen